import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { DownloadDocument } from '@keytrade/components-downloaddocument';

import DefaultLayout from '@/components/DefaultLayout';
import Container from '@/components/Container';

const DownloadWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
`;

type Props = {
  data: {
    contentfulAssetLink: any;
  };
};

const AssetLinkTemplate: React.FC<Props> = (props) => {
  const assetLink = props.data.contentfulAssetLink;
  const assetURL = `https:${assetLink.asset.file.url}`;
  let params = new URLSearchParams();
  if (typeof window !== 'undefined') {
    params = new URLSearchParams(window.location.search);
  }
  const downloadParam = params.get('download');

  const openFile = (e) => {
    if (e) e.preventDefault();

    if (typeof window !== 'undefined') {
      window.location = assetURL;
    }
  };

  // force download
  if (typeof window !== 'undefined') {
    // if url as parameter "download=true"
    if (downloadParam === 'true') {
      const anchor = document.createElement('a');
      anchor.href = assetURL;
      anchor.target = '_blank';
      anchor.setAttribute('download', 'download');
      anchor.click();
      return null;
    }
  }

  return (
    <DefaultLayout>
      <DownloadWrapper>
        <object
          data={assetURL}
          type='application/pdf'
          width='100%'
          height='100%'
        >
          <Container narrow={true}>
            <DownloadDocument href={assetURL} onClick={openFile}>
              {assetLink.title}
            </DownloadDocument>
          </Container>
        </object>
      </DownloadWrapper>
    </DefaultLayout>
  );
};

export default AssetLinkTemplate;

export const assetLinkQuery = graphql`
  query AssetLinkQuery($contentfulId: String!, $locale: String) {
    contentfulAssetLink(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      title
      slug
      node_locale
      asset {
        file {
          url
        }
      }
    }
  }
`;
